<template lang="pug">
section.products.home-section
  .container.framed
    el-row(:gutter="32")
      el-col(:sm="12")
        .products__header
          h2 Recently added
      el-col(:sm="12")
        .products__actions
          nuxt-link(:to="{ name: 'products' }" title="Browse Catalog") Browse Catalog
          nuxt-link.el-button.el-button--highlight(
            v-if="!$store.getters['authentication/isLogged']"
            :to="{ name: 'register' }"
            @click.native="$amplitude.track('HOMEPAGE_REGISTER', { zone: 'products-section' })"
            title="Sign up to buy") Sign up to buy

    .products__carousel
      swiper.products__grid(
        ref="swiper"
        :options="swiperOptions"
        :auto-destroy="false")
        swiper-slide(
          v-for="i, k in products"
          :key="k")
          product-card.products__item(
            :data="i"
            :productCardType="PRODUCT_CARD_TYPES.RECOMMENDATION_CAROUSEL_HOME"
            :trackProps="{ zone: 'HOMEPAGE_PRODUCTS', position: k + 1 }")

      .products__navigation
        span.products__previous(@click="$refs.swiper.swiperInstance.slidePrev()") &lt;
        span.products__next(@click="$refs.swiper.swiperInstance.slideNext()") &gt;
</template>

<script>
import { Swiper as SwiperClass, Autoplay } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
import { PRODUCT_CARD_TYPES } from '~/settings'

import ProductCard from '~/components/products/ProductCard'

SwiperClass.use([Autoplay])
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  components: {
    Swiper,
    SwiperSlide,
    ProductCard
  },

  data () {
    return {
      PRODUCT_CARD_TYPES,
      products: [],
      swiperOptions: {
        autoplay: {
          delay: 4000
        },
        loop: true,
        slidesPerView: 2,
        spaceBetween: 16,
        breakpoints: {
          768: {
            slidesPerView: 3
          },
          992: {
            slidesPerView: 5
          }
        }
      }
    }
  },

  fetch () {
    return Promise.all([
      this.getNewProducts()
    ])
  },

  methods: {
    getNewProducts () {
      return this.$api.catalog
        .searchProducts({
          category_id: 0,
          per_page: 12
        })
        .then(({ results }) => {
          this.products = results
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  h2 {
    font-size: 40px;
    line-height: 120%;
    margin: 4px 0;
  }
  &__carousel {
    position: relative;
  }
  &__grid {
    text-align: left;
    padding: 48px 0 120px 0;
    --swiper-theme-color: #{$--color-primary};
    transition: opacity .3s ease-in-out;
    &:not(.swiper-container-initialized) {
      opacity: 0;
      display: flex;
      flex-wrap: nowrap;
      height: 530px;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;
    @include media(md) {
      justify-content: flex-end;
    }
  }
  &__navigation {
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    left: -1.5em;
    right: -1.5em;
    z-index: 3;
    pointer-events: none;
    > * {
      display: block;
      font-size: 16px;
      width: 3em;
      height: 3em;
      border-radius: 3em;
      background: white;
      color: $--color-text-secondary;
      line-height: 3em;
      text-align: center;
      cursor: pointer;
      transition: transform .2s ease-in-out;
      box-shadow: 0 10px 20px 0 rgba(black, .2);
      pointer-events: all;
      &:hover {
        transform: scale(1.1);
      }
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
  &__item.product-card {
    border-radius: 16px;
    border: none;
    &:hover {
      outline: none;
      border-radius: 16px;
    }
    ::v-deep {
      .caption {
        padding: 16px 12px;
      }
    }
  }
}
</style>
